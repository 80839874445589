import React from 'react';

import SidebarLink from '$ui/sidebarLink';

import DashboardNavigation from '$components/layout/dashboard/navigation';

import UserIcon from '$assets/user.svg';

const NavBar = () => (
  <DashboardNavigation>
    <SidebarLink href='/admin/dashboard' icon={UserIcon}>
      Dashboard
    </SidebarLink>
    <SidebarLink href='/admin/complaints' icon={UserIcon}>
      Complaints
    </SidebarLink>
    <SidebarLink href='/admin/users' icon={UserIcon}>
      Users
    </SidebarLink>
    <SidebarLink href='/admin/account' icon={UserIcon}>
      Account
    </SidebarLink>
  </DashboardNavigation>
);

export default NavBar;

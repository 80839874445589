import React, { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { updateComplaint } from '$utils/queryFunctions';

import Button from '$ui/button';

import TickIcon from '$assets/tick.svg';
import CancelIcon from '$assets/cancel.svg';

const StatusChanger = ({ complaintId, complaintStatus }) => {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: updateComplaint,
    onSuccess(response) {
      queryClient.invalidateQueries(['complaint', complaintId], response);
    },
    onError(err) {
      console.log(err.response?.data || err);
    }
  });

  const handleStatusUpdate = useCallback(
    status => async () => {
      try {
        await mutateAsync({
          complaintId,
          status
        });
      } catch (err) {
        toast.error(err.response?.data.message || 'Network error');
      }
    },
    [complaintId, mutateAsync]
  );

  switch (complaintStatus) {
    case 'PENDING':
      return (
        <div className='bg-white py-6 p-8 rounded-2xl flex items-center justify-between tab-port:flex-col tab-port:items-start tab-port:space-y-5 phone:p-5'>
          <h3>
            A complaint has been received. Make a decision after reviewing all
            the details of this complaint.
          </h3>

          <div className='space-x-4'>
            <Button
              variant='filled'
              className='bg-green-400 border-green-400 hover:bg-green-500 hover:border-green-500'
              onClick={handleStatusUpdate('ACCEPTED')}>
              Accept
            </Button>
            <Button
              variant='filled'
              className='bg-red-400 border-red-400 hover:bg-red-500 hover:border-red-500'
              onClick={handleStatusUpdate('DECLINED')}>
              Decline
            </Button>
          </div>
        </div>
      );

    case 'ACCEPTED':
      return (
        <div className='flex items-center space-x-3 bg-white py-6 p-8 rounded-2xl phone:p-5'>
          <TickIcon className='h-5 w-5 fill-green-400' />
          <span>
            This complaint has been accepted. Please wait for the client to
            process the payment.
          </span>
        </div>
      );

    case 'DECLINED':
      return (
        <div className='flex items-center mt-6 space-x-3 bg-white py-6 p-8 rounded-2xl phone:p-5'>
          <CancelIcon className='h-5 w-5 fill-red-400' />
          <span>This complaint has been rejected and closed.</span>
        </div>
      );

    case 'PAID':
      return (
        <div className='flex items-center mt-6 space-x-3 bg-white py-6 p-8 rounded-2xl phone:p-5'>
          The client has successfully made the payment. This case can be
          proceeded.
        </div>
      );

    case 'RESOLVED':
      return (
        <div className='flex items-center mt-6 space-x-3 bg-white py-6 p-8 rounded-2xl phone:p-5'>
          This complaint has been resolved and closed.
        </div>
      );

    default:
      return null;
  }
};

export default StatusChanger;

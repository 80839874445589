import React from 'react';
import classNames from 'classnames';
import { useQuery } from 'react-query';

import Heading from '$ui/heading';
import Loader from '$ui/loader';

import AdminLayout from '$components/layout/admin';

import { getComplaintById } from '$utils/queryFunctions';

import PendingIcon from '$assets/pending.svg';
import AcceptIcon from '$assets/accept.svg';
import PaidIcon from '$assets/paid.svg';
import UnpaidIcon from '$assets/unpaid.svg';
import ResolvedIcon from '$assets/tick.svg';
import StatusChanger from '$components/admin/statusChanger';

const statusTypes = ['PENDING', 'ACCEPTED', 'PAID', 'RESOLVED'];

const icons = [
  <PendingIcon className='h-5 fill-slate-500' />,
  <AcceptIcon className='h-5 fill-slate-500' />,
  <UnpaidIcon className='h-5 fill-slate-500' />,
  <PaidIcon className='h-5 fill-slate-500' />,
  <ResolvedIcon className='h-5 fill-slate-500' />
];

const ComplaintDetailsPage = ({ params }) => {
  const { data: complaint, isLoading } = useQuery(
    ['complaint', params.id],
    getComplaintById
  );

  if (isLoading)
    return (
      <AdminLayout
        title='Complaint Details'
        mainTagClassName='h-screen flex items-center justify-center'>
        <Loader />
      </AdminLayout>
    );

  return (
    <AdminLayout
      title={`Complaint | ${complaint.id}`}
      mainTagClassName='overflow-y-scroll h-screen tab-port:h-auto'>
      <section className='p-10 phone:px-5'>
        <Heading variant='tertiary-left'>Complaint #{complaint.id}</Heading>

        <p className='text-xs'>
          In case of any confusion / conflicts, CRO clients will reach out to
          administrators using this complaint id.
        </p>

        {complaint.status !== 'DECLINED' && (
          <div className='bg-primary text-white py-6 px-8 rounded-2xl font-semibold my-12 flex items-center tab-port:p-5'>
            {statusTypes.map((status, i) => (
              <React.Fragment key={i}>
                <div
                  className={classNames(
                    'h-10 w-10 rounded-full flex justify-center items-center tab-port:h-4 tab-port:w-4',
                    statusTypes.indexOf(complaint.status) >= i
                      ? 'bg-green-200'
                      : 'bg-white'
                  )}>
                  {icons[i]}
                </div>

                <div className='relative flex-1 text-center uppercase'>
                  <p className='absolute -top-6 left-1/2 -translate-x-1/2 tab-port:text-xs tab-port:-top-5 phone:text-atmoic'>
                    {status}
                  </p>
                  <div
                    className={classNames(
                      'h-1 phone:h-[2px]',
                      statusTypes.indexOf(complaint.status) >= i
                        ? 'bg-green-200'
                        : 'bg-white'
                    )}></div>
                </div>
              </React.Fragment>
            ))}
            <div
              className={classNames(
                'h-10 w-10 rounded-full flex justify-center items-center tab-port:h-4 tab-port:w-4',
                statusTypes.indexOf(complaint.status) >= statusTypes.length - 1
                  ? 'bg-green-200'
                  : 'bg-white'
              )}>
              {icons.at(-1)}
            </div>
          </div>
        )}

        <StatusChanger
          complaintId={complaint.id}
          complaintStatus={complaint.status}
        />
      </section>

      <section className='border-t-2 border-gray-200 p-10 phone:px-5'>
        <h3 className='font-semibold text-lg capitalize text-black'>
          Victim Info
        </h3>

        <ul className='capitalize space-y-1 mt-3'>
          <li>
            <span className='font-semibold'>user id: </span>
            {complaint.victim.id}
          </li>
          <li>
            <span className='font-semibold'>full name: </span>
            {complaint.victim.fullName}
          </li>
          <li>
            <span className='font-semibold'>phone: </span>
            {complaint.victim.phone}
          </li>
          <li>
            <span className='font-semibold'>email: </span>
            {complaint.victim.email}
          </li>
          <li>
            <span className='font-semibold'>state: </span>
            {complaint.victim.state}
          </li>
          <li>
            <span className='font-semibold'>city: </span>
            {complaint.victim.city}
          </li>
          <li>
            <span className='font-semibold'>pincode: </span>
            {complaint.victim.pincode}
          </li>
          <li>
            <span className='font-semibold'>address: </span>
            {complaint.victim.address}
          </li>
          <li>
            <span className='font-semibold'>date of birth: </span>
            {new Intl.DateTimeFormat().format(
              new Date(complaint.victim.dateOfBirth)
            )}
          </li>
        </ul>
      </section>

      <section className='border-t-2 border-gray-200 p-10 grid grid-cols-[2.5fr,1fr] gap-10 tab-port:grid-cols-none phone:px-5 phone:gap-5'>
        <div className='border-2 border-gray-200 p-5 space-y-2 rounded-md'>
          <h3 className='font-semibold text-lg capitalize text-black'>
            description
          </h3>
          <p>{complaint.issueDescription}</p>
        </div>

        <div className='border-2 border-gray-200 p-5 space-y-2 rounded-md'>
          <h3 className='font-semibold text-lg capitalize text-black'>
            opposition
          </h3>

          <ul>
            <li>
              <span className='font-semibold'>Against:</span>{' '}
              {complaint.opposition.name}
            </li>
            <li>
              <span className='font-semibold'>Person:</span>{' '}
              {complaint.opposition.personOfImportance}
            </li>
            <li>
              <span className='font-semibold'>Email:</span>{' '}
              {complaint.opposition.email}
            </li>
            <li>
              <span className='font-semibold'>Phone:</span>{' '}
              {complaint.opposition.phone}
            </li>
          </ul>
        </div>

        <div className='col-span-full border-2 border-gray-200 p-5 space-y-2 rounded-md'>
          <h3 className='font-semibold text-lg capitalize text-black'>
            Attached files
          </h3>
        </div>
      </section>

      <section className='border-t-2 border-gray-200 p-10 phone:px-5'>
        <h3 className='font-semibold text-lg capitalize text-black'>
          Additional information
        </h3>

        <ul className='capitalize space-y-1 mt-3'>
          <li>
            <span className='font-semibold'>loss amount: </span>₹
            {complaint.lossAmount}
          </li>
          <li>
            <span className='font-semibold'>issue type: </span>
            {complaint.issueType}
          </li>
          <li>
            <span className='font-semibold'>transaction date: </span>
            {new Intl.DateTimeFormat().format(
              new Date(complaint.transactionDate)
            )}
          </li>
          <li>
            <span className='font-semibold'>transaction medium: </span>
            {complaint.transactionMedium}
          </li>
          <li>
            <span className='font-semibold'>transaction purpose: </span>
            {complaint.transactionPurpose}
          </li>
          <li>
            <span className='font-semibold'>transaction method: </span>
            {complaint.transactionMethod}
          </li>
          <li>
            <span className='font-semibold'>
              initial meeting with opposition:{' '}
            </span>
            {complaint.initialMeetWithOpposition}
          </li>
          <li>
            <span className='font-semibold'>
              has given disclosure consent?{' '}
            </span>
            {complaint.hasGivenDisclosureConsent ? 'yes' : 'no'}
          </li>
          <li>
            <span className='font-semibold'>has signed an agreement? </span>
            {complaint.hasSignedAgreement ? 'yes' : 'no'}
          </li>
          <li>
            <span className='font-semibold'>has contacted lawyer? </span>
            {complaint.hasContactedLawyer ? 'yes' : 'no'}
          </li>
          <li>
            <span className='font-semibold'>
              has tried settlement with opposition?{' '}
            </span>
            {complaint.hasTriedSettlementWithOpposition ? 'yes' : 'no'}
          </li>
          <li>
            <span className='font-semibold'>
              has approached other agencies?{' '}
            </span>
            {complaint.hasApproachedOtherAgencies ? 'yes' : 'no'}
          </li>
          <li>
            <span className='font-semibold'>has gone court? </span>
            {complaint.hasGoneCourt ? 'yes' : 'no'}
          </li>
          <li>
            <span className='font-semibold'>has been sued? </span>
            {complaint.hasBeenSued ? 'yes' : 'no'}
          </li>
          <li>
            <span className='font-semibold'>
              Suggested resolution apporach:{' '}
            </span>
            {complaint.suggestedResolutionApproach}
          </li>
        </ul>
      </section>
    </AdminLayout>
  );
};

export default ComplaintDetailsPage;

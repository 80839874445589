import React from 'react';

import ProtectedRoute from '$components/protectedRoute';
import DashboardLayout from '$components/layout/dashboard';
import AdminNavigation from './navBar';

const AdminLayout = props => {
  return (
    <ProtectedRoute variant='super-admin'>
      <DashboardLayout {...props} navBar={AdminNavigation} />
    </ProtectedRoute>
  );
};

export default AdminLayout;
